export const ROLES_MANAGEMENT_TABS = [
  {
    key: 'all-users',
    label: 'All User',
    items: [
      {
        privilege: 'HomescreenAllUsers',
        title: 'All Users',
        items: [],
      },
    ],
  },
  {
    key: 'teleconsult',
    label: 'Teleconsult',
    items: [
      {
        privilege: 'DoctorManagement',
        title: 'Doctor Management',
        items: [],
      },
      {
        privilege: 'PatientManagement',
        title: 'Patient Management',
        items: [],
      },
      { privilege: 'CAManagement', title: 'CA Management', items: [] },
      { privilege: 'ConsultRoom', title: 'Consult Room', items: [] },
      { privilege: 'VisitRecords', title: 'Visit Records', items: [] },
      { privilege: 'ConsultList', title: 'Consult List', items: [] },
      {
        privilege: 'AppointmentConfig',
        title: 'Appointment Config',
        items: [],
      },

      {
        privilege: 'Appointments',
        title: 'Appointments',
        items: [],
      },
      { privilege: 'MHSManagement', title: 'MHS Management', items: [] },
      {
        privilege: 'TeleconsultAnalytics',
        title: 'Teleconsult Analytics',
        items: [],
      },
    ],
  },
  {
    key: 'telecounsel',
    label: 'Counselling',
    items: [
      {
        privilege: 'CounselQueue',
        title: 'Queue',
        items: [],
      },
      {
        privilege: 'CounselAll',
        title: 'All counsellings',
        items: [],
      },
      {
        privilege: 'CounselAppointments',
        title: 'Appointments',
        items: [],
      },
      {
        privilege: 'ResourcesManagement',
        title: 'Resources management',
        items: [],
        isOpen: true,
        child: [
          {
            privilege: 'CounsellorManagement',
            title: 'Counsellors',
            items: [],
          },
          {
            privilege: 'CounsellorExecutivesManagement',
            title: 'Counsellor executives',
            items: [],
          },
        ],
      },
      {
        privilege: 'CounsellorConfig',
        title: 'Appointment configuration',
        items: [],
      },
    ],
  },
  {
    key: 'clinic',
    label: 'Clinic',
    items: [
      {
        privilege: 'ClinicAppointments',
        title: 'Clinic appointments',
        items: [],
      },
      {
        privilege: 'ClinicSetUpMenu',
        title: 'Clinic setup',
        items: [],
        isOpen: true,
        child: [
          {
            privilege: 'ClinicSetUp',
            title: 'Clinic locations',
            items: [],
          },
          {
            privilege: 'ClinicServices',
            title: 'Clinic services',
            items: [],
          },
        ],
      },
      { privilege: 'QueueRequest', title: 'Queue Request', items: [] },
    ],
  },
  {
    key: 'health-screening',
    label: 'Health Screening',
    items: [
      {
        privilege: 'Upcoming',
        title: 'Upcoming',
        items: [],
      },
      {
        privilege: 'HealthScreeningAppointments',
        title: 'All screenings',
        items: [],
      },
      {
        privilege: 'EOrder',
        title: 'E-order',
        items: [],
      },
      {
        title: 'Closing Report',
        privilege: 'ClosingReport',
        items: [],
      },
      {
        title: 'Backroom',
        privilege: 'Backroom',
        items: [],
      },
      {
        privilege: 'Setup',
        title: 'Setup',
        items: [],
        isHidden: true,
        child: [
          {
            privilege: 'SiteConfig',
            title: 'Location',
            items: [],
          },
          {
            privilege: 'CompanyManagement',
            title: 'Company',
            items: [],
          },
        ],
      },
      {
        privilege: 'Configuration',
        title: 'Configuration',
        items: [],
      },
    ],
  },
  {
    key: 'telefit',
    label: 'Telefit',
    items: [
      {
        privilege: 'Workout',
        title: 'Workout',
        isOpen: true,
        child: [
          {
            privilege: 'Categories',
            title: 'Categories',
            items: [],
          },
          {
            privilege: 'Livestream',
            title: 'Livestream',
            items: [],
          },
          {
            privilege: 'OnDemand',
            title: 'On Demand',
            items: [],
          },
          {
            privilege: 'StudioClasses',
            title: 'Studio Classes',
            items: [],
          },
        ],
      },
      {
        privilege: 'UpcomingWorkout',
        title: 'Upcoming Workout',
        items: [],
      },
      {
        privilege: 'StudioManagement',
        title: 'Studio Management',
        items: [],
      },
      {
        privilege: 'TrainerManagement',
        title: 'Trainer Management',
        items: [],
      },
      {
        privilege: 'InternalTrainerManagement',
        title: 'Internal Trainer Management',
        items: [],
      },
    ],
  },
  {
    key: 'minc-admin',
    label: 'Minc Admin',
    items: [
      {
        privilege: 'UserManagement',
        title: 'User Management',
        items: [],
      },
      {
        privilege: 'StuckAccounts',
        title: 'Stuck Accounts',
        items: [],
      },
      {
        privilege: 'RoleManagement',
        title: 'Role Management',
        items: [],
      },
      {
        privilege: 'EcommerceManagement',
        title: 'E-commerce Management',
        items: [],
      },
      { privilege: 'PromoCode', title: 'Promo Code', items: [] },
      {
        privilege: 'DeletionWorklist',
        title: 'Deletion Worklist',
        items: [],
      },
      {
        privilege: 'Analytics',
        title: 'Analytics',
        items: [],
      },
      {
        privilege: 'BannerManagement',
        title: 'Banner Management',
        items: [],
      },
      {
        privilege: 'MarketingMessage',
        title: 'Marketing messages',
        items: [],
      },
      { privilege: 'LabResult', title: 'Lab Result', items: [] },
      {
        privilege: 'ImportHSAppointments',
        title: 'Import HS Appointments',
        items: [],
      },
    ],
  },
];

export const renderTablePermission = () => {
  const tableDefault = ROLES_MANAGEMENT_TABS.map((table) => ({
    ...table,
    items: [],
  }));

  return tableDefault;
};

export const ROLE_TYPE = [
  { roleType: 'SuperAdmin', label: 'Admin' },
  { roleType: 'Doctor', label: 'Doctor' },
  { roleType: 'CA', label: 'CA' },
  { roleType: 'ClinicManager', label: 'Clinic Manager' },
  { roleType: 'ClinicAdmin', label: 'Clinic Admin' },

  // { roleType: 'Swabber', label: 'Swabber' },
  { roleType: 'MMSPublicist', label: 'MMS Publicist' },
  { roleType: 'MMSProjectManager', label: 'MMS Project Manager' },
  { roleType: 'MSCManager', label: 'MSC Manager' },
  { roleType: 'MSCCSA', label: 'MSC CSA' },
  { roleType: 'Staff', label: 'Staff' },
  { roleType: 'RegistrationStaff', label: 'Registration Staff' },

  { roleType: 'BackroomStaff', label: 'Backroom Staff' },
  { roleType: 'ScreeningDoctor', label: 'Screening Doctor' },
  { roleType: 'DoctorAdmin', label: 'Doctor Admin' },
  { roleType: 'Nurse', label: 'Nurse' },
  { roleType: 'CSO', label: 'CSO' },
  { roleType: 'CSOManager', label: 'CSO Manager' },

  { roleType: 'SaleManager', label: 'Sales Manager' },
  { roleType: 'SaleExec', label: 'Sales Exec' },
  { roleType: 'FinanceManager', label: 'Finance Manager' },
  { roleType: 'FinanceExec', label: 'Finance Exec' },
  { roleType: 'TeleFITSuperAdmin', label: 'Telefit Super Admin' },

  { roleType: 'TeleFITAdmin', label: 'Telefit Admin' },
  { roleType: 'TeleFITStudio', label: 'Telefit Studio' },
  { roleType: 'InternalTrainer', label: 'Telefit Internal Trainer' },
  { roleType: 'Manager', label: 'Telefit Host' },
  { roleType: 'Trainer', label: 'Telefit Trainer' },

  { roleType: 'MHS', label: 'MHS' },
  { roleType: 'LabAdmin', label: 'MHS Support' },
  { roleType: 'MarketingManager', label: 'Marketing Manager' },
  { roleType: 'Counsellor', label: 'Counsellor' },
  { roleType: 'CounsellorExecutive', label: 'Counsellor Executive' },
];

export const ROLE_TYPE_CLINIC_ADMIN = [
  { roleType: 'Doctor', label: 'Doctor' },
  { roleType: 'CA', label: 'CA' },
  { roleType: 'ClinicManager', label: 'Clinic Manager' },
];
export const ROLE_TYPE_MMS_PROJECT_MANAGER = [
  { roleType: 'Staff', label: 'Staff' },
  { roleType: 'RegistrationStaff', label: 'Registration Staff' },
];
export const ROLE_TYPE_CA = [{ roleType: 'CA', label: 'CA' }];

export const ROLE_MERGE_TRAINER = [
  { roleType: 'SuperAdmin', label: 'Admin' },
  { roleType: 'Doctor', label: 'Doctor' },
  { roleType: 'CA', label: 'CA' },
  { roleType: 'ClinicManager', label: 'Clinic Manager' },
  { roleType: 'ClinicAdmin', label: 'Clinic Admin' },

  { roleType: 'Swabber', label: 'Swabber' },
  { roleType: 'MMSPublicist', label: 'MMS Publicist' },
  { roleType: 'MMSProjectManager', label: 'MMS Project Manager' },
  { roleType: 'MSCManager', label: 'MSC Manager' },
  { roleType: 'MSCCSA', label: 'MSC CSA' },

  { roleType: 'Staff', label: 'Staff' },
  { roleType: 'RegistrationStaff', label: 'Registration Staff' },

  { roleType: 'BackroomStaff', label: 'Backroom Staff' },
  { roleType: 'ScreeningDoctor', label: 'Screening Doctor' },
  { roleType: 'DoctorAdmin', label: 'Doctor Admin' },
  { roleType: 'Nurse', label: 'Nurse' },
  { roleType: 'CSO', label: 'CSO' },
  { roleType: 'CSOManager', label: 'CSO Manager' },

  { roleType: 'SaleManager', label: 'Sales Manager' },
  { roleType: 'SaleExec', label: 'Sales Exec' },
  { roleType: 'FinanceManager', label: 'Finance Manager' },
  { roleType: 'FinanceExec', label: 'Finance Exec' },
  { roleType: 'TeleFITSuperAdmin', label: 'Telefit Super Admin' },

  { roleType: 'TeleFITAdmin', label: 'Telefit Admin' },
  { roleType: 'TeleFITStudio', label: 'Telefit Studio' },
  { roleType: 'Manager', label: 'Telefit Host' },
  { roleType: 'Trainer', label: 'Telefit Trainer' },
  { roleType: 'MHS', label: 'MHS' },

  { roleType: 'LabAdmin', label: 'MHS Support' },
  { roleType: 'MarketingManager', label: 'Marketing Manager' },
  { roleType: 'Counsellor', label: 'Counsellor' },
  { roleType: 'CounsellorExecutive', label: 'Counsellor Executive' },
];
