import { StatusComponent } from '../all-screenings/component/StatusComponent';
import TableCellFilted from '../common/componentUI/TableCellFilted';
import clinicApptDispatcher from './action';
import GroupToolbar from './component/GroupToolbar';
import ModalAddOrEditClinicAppt from './component/ModalAddOrEditClinicAppt';
import ModalCreateClinicAppt from './component/ModalCreateClinicAppt';
import {
  CLINIC_APPT_STATUS,
  CLINIC_STATUSES,
  COLUMN_CLINIC_APPOINTMENT,
  TYPE_MODAL,
} from './constants';
import {
  CloseCircle,
  CreateNewIcon,
  EditIcon,
  ExportIcon,
  NewLocation,
  NoDataScreenings,
  ReverseIcon,
} from '@/assets/svg';
import { handleSelectAll } from '@/helpers';
import CustomMenu from '@/new-components/CustomMenu';
import CustomMenuActions from '@/new-components/CustomMenuActions';
import ModalConfirmation from '@/new-components/CustomModal/ModalConfirmation';
import customToast from '@/new-components/CustomNotification';
import CustomPaperContainer from '@/new-components/CustomPaperContainer';
import CustomHeader from '@/new-components/CustomPaperContainer/CustomHeader';
import CustomTable from '@/new-components/CustomTable';
import CustomTooltip from '@/new-components/CustomTooltip';
import {
  Button,
  Drawer,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { MoreVertRounded } from '@material-ui/icons';
import { debounce, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const ClinicAppointment = () => {
  const { list: data, paging } = useSelector((state) => state.clinicAppt);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [typeModal, setTypeModal] = useState(null);

  const [sortOption, setSortOption] = useState({});
  const [searchKey, setSearchKey] = useState('');

  const [locationIds, setLocationIds] = useState([]);
  const [purposeIds, setPurposeIds] = useState([]);
  const [clinicStatuses, setClinicStatuses] = useState([]);
  const [appointmentDate, setAppointmentDate] = useState([
    moment().startOf('date'),
    moment().endOf('date'),
  ]);

  const [filterOption, setFilterOption] = useState({
    fromDate: appointmentDate[0],
    toDate: appointmentDate[1],
  });

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const renderListActions = (selectedItem) => {
    if (!selectedItem) return [];
    const { status, date } = selectedItem;

    let listActions = [];

    switch (status) {
      case CLINIC_STATUSES.Upcoming:
        var isCurrentDate = moment(date).isSame(new Date(), 'day');
        if (isCurrentDate) {
          listActions = listActions.concat([
            {
              key: 'check-in-location',
              icon: NewLocation,
              label: 'Check in',
              onClick: onCheckInLocation,
            },
          ]);
        }
        listActions = listActions.concat(
          {
            key: 'edit',
            icon: EditIcon,
            label: 'Edit appointment',
            styles: { width: 20, height: 20 },
            onClick: () => {
              setAnchorEl(null);
              onShowModal(TYPE_MODAL.Edit);
            },
          },
          {
            key: 'cancel',
            icon: CloseCircle,
            label: 'Cancel appointment',
            color: '#DB3D49',
            styles: { width: 18, height: 18 },
            onClick: () => {
              setAnchorEl(null);
              setShowCancelModal(true);
            },
          }
        );
        break;

      case CLINIC_STATUSES.Missed:
        listActions = listActions.concat({
          key: 'reschedule',
          icon: ReverseIcon,
          label: 'Reschedule appointment',
          styles: { width: 20, height: 20 },
          onClick: () => {
            setAnchorEl(null);
            onShowModal(TYPE_MODAL.Reschedule);
          },
        });
        break;

      default:
        break;
    }

    return listActions;
  };

  const onFilterOptionChange = (key) => (e, newValue) => {
    let value = e.target.value;

    switch (key) {
      case 'statuses':
        value = handleSelectAll(
          value,
          CLINIC_APPT_STATUS.map((item) => item.key),
          clinicStatuses
        );
        setClinicStatuses(value);
        break;

      default:
        break;
    }

    setFilterOption({
      ...filterOption,
      [key]: value,
    });
  };

  const onExportExcel = () => {
    if (data.length === 0) {
      return customToast(
        'error',
        'There is no data available to export the excel file'
      );
    }
    let params = {
      ...sortOption,
      ...filterOption,
      search: searchKey,
    };

    clinicApptDispatcher.getExportExcel(params);
  };

  const onCancelAppointment = () => {
    if (isEmpty(selectedItem)) return;

    clinicApptDispatcher.cancelClinicAppointment(selectedItem.id, () => {
      customToast('success', 'Cancel Appointment Success');
      fetchData();
      setShowCancelModal(false);
    });
  };

  const onCheckInLocation = () => {
    setAnchorEl(null);
    clinicApptDispatcher.checkInClinicAppointment(selectedItem.id, () => {
      fetchData(searchKey, paging, sortOption, filterOption);
      renderToast(selectedItem.patientName);
    });
  };

  const onResetFilterOps = () => {
    setLocationIds([]);
    setPurposeIds([]);
    setClinicStatuses([]);
    setFilterOption({});
    setAppointmentDate([]);
  };

  const onShowModal = (type) => {
    setShowModal(true);
    setTypeModal(type);
  };

  const onCloseModal = () => {
    setShowModal(false);
    setTypeModal(null);
  };

  const fetchData = async (
    search = searchKey,
    pagingData = paging,
    sortOptionData = sortOption,
    filterOptionData = filterOption
  ) => {
    clinicApptDispatcher.getData(
      search,
      pagingData,
      sortOptionData,
      filterOptionData
    );
  };

  const debounceLoadData = useCallback(
    debounce((searchKey, paging, sortOption, filterOption) => {
      fetchData(
        searchKey,
        {
          pageIndex: 0,
          pageSize: paging.pageSize,
        },
        sortOption,
        filterOption
      );
    }, 500),
    []
  );

  useEffect(() => {
    debounceLoadData(searchKey, paging, sortOption, filterOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, sortOption, filterOption]);

  const TableHeader = () => (
    <TableHead>
      <TableRow>
        {COLUMN_CLINIC_APPOINTMENT.map((item) =>
          item.sortBy ? (
            <TableCellFilted
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              sortBy="Date"
              sortOption={sortOption}
              onSortChange={() => {
                let newSortOption = {
                  sortBy: 'BookedApptDate',
                  orderBy: sortOption.orderBy !== 'Asc' ? 'Asc' : 'Desc',
                };
                setSortOption(newSortOption);
              }}
              style={{
                minWidth: item.minWidth || 'unset',
                width: item.width || 'unset',
              }}
              isHidden={false}
            />
          ) : (
            <TableCellFilted
              key={item.stateValue}
              label={item.label}
              stateValue={item.stateValue}
              style={{
                minWidth: item.minWidth || 'unset',
                width: item.width || 'unset',
              }}
              isHidden={false}
            />
          )
        )}
      </TableRow>
    </TableHead>
  );
  const renderDateTime = (row) => {
    let startDateFormat, startTimeFormat;
    const { date } = row;

    if (!date) return null;

    startDateFormat = moment(date).format('DD/MM/YYYY');
    startTimeFormat = moment(date).format('LT');
    return `${startDateFormat} ${startTimeFormat}`;
  };

  const renderTableBody = (row) => {
    return (
      <>
        <TableCell>
          <CustomTooltip content={row.patientName} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.patientNric} />
        </TableCell>
        <TableCell>
          <CustomTooltip content={row.purposeName} />
        </TableCell>
        <TableCell>
          <CustomTooltip
            content={
              !isEmpty(row.subPurposes.filter((it) => it && it !== null))
                ? row.subPurposes.join(', ')
                : '—'
            }
          />
        </TableCell>
        <TableCell>{renderDateTime(row)}</TableCell>
        <TableCell>
          <CustomTooltip content={row.locationName} />
        </TableCell>
        <TableCell>
          <StatusComponent status={row.status} />
        </TableCell>
        <TableCell>
          <IconButton
            disabled={[
              CLINIC_STATUSES.Cancelled,
              CLINIC_STATUSES.Completed,
            ].includes(row.status)}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setSelectedItem(row);
            }}
          >
            <MoreVertRounded />
          </IconButton>
        </TableCell>
      </>
    );
  };

  const renderToast = (data, typeModal) => {
    let description = '',
      title = '';

    switch (typeModal) {
      case TYPE_MODAL.Create:
        description = (
          <>
            Appointment for patient{' '}
            <strong style={{ fontWeight: 600 }}>{data}</strong> has been
            successully created.
          </>
        );
        title = 'Appointment created';
        break;
      case TYPE_MODAL.Edit:
        description = (
          <>
            Appointment for patient{' '}
            <strong style={{ fontWeight: 600 }}>{data}</strong> has been
            successully updated.
          </>
        );
        title = 'Appointment updated';
        break;
      case TYPE_MODAL.Reschedule:
        description = (
          <>
            Appointment for patient{' '}
            <strong style={{ fontWeight: 600 }}>{data}</strong> has been
            successully rescheduled.
          </>
        );
        title = 'Appointment rescheduled';
        break;
      default:
        // Check in
        description = (
          <>
            Patient <strong style={{ fontWeight: 600 }}>{data}</strong> has been
            successully checked in.
          </>
        );
        title = 'Checked in';
        break;
    }

    return customToast('success', <span>{description}</span>, title);
  };

  const handleUpdateAppointment = (type, data, ptName) => {
    switch (type) {
      case TYPE_MODAL.Edit:
      case TYPE_MODAL.Reschedule:
        clinicApptDispatcher.editClinicAppointment(
          selectedItem.id,
          data,
          () => {
            fetchData(searchKey, paging, sortOption, filterOption);
            renderToast(ptName, type);
            onCloseModal();
          }
        );

        break;
      case TYPE_MODAL.Create:
        clinicApptDispatcher.createClinicAppointment(data, () => {
          fetchData(searchKey, paging, sortOption, filterOption);
          renderToast(ptName, type);
          onCloseModal();
        });

        break;
      default:
        break;
    }
  };

  return (
    <>
      <CustomMenu
        id="simple-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <CustomMenuActions listActions={renderListActions(selectedItem)} />
      </CustomMenu>
      <CustomPaperContainer
        header={
          <CustomHeader
            searchPlaceholder="Search by name or ID number..."
            title="Clinic appointments"
            setSearchKey={setSearchKey}
            searchKey={searchKey}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            sortOption={sortOption}
            fetchData={fetchData}
            renderToolbar={() => (
              <GroupToolbar
                filterOption={filterOption}
                setFilterOption={setFilterOption}
                onFilterOptionChange={onFilterOptionChange}
                onResetFilterOps={onResetFilterOps}
                locationIds={locationIds}
                setLocationIds={setLocationIds}
                purposeIds={purposeIds}
                setPurposeIds={setPurposeIds}
                clinicStatuses={clinicStatuses}
                setClinicStatuses={setClinicStatuses}
                appointmentDate={appointmentDate}
                setAppointmentDate={setAppointmentDate}
              />
            )}
            renderButton={() => (
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<ExportIcon width={20} height={20} />}
                  onClick={onExportExcel}
                >
                  Export
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<CreateNewIcon />}
                  onClick={() => {
                    setSelectedItem(null);
                    onShowModal(TYPE_MODAL.Create);
                  }}
                >
                  Create new
                </Button>
              </>
            )}
          />
        }
      >
        <CustomTable
          data={data}
          paging={paging}
          header={TableHeader}
          renderRow={(row, i) => renderTableBody(row)}
          noDataHelperText="No appointments"
          noDataHelperSubText="Please come back at another time."
          Icon={NoDataScreenings}
          totalCols={COLUMN_CLINIC_APPOINTMENT.length}
          fetchData={fetchData}
        />
      </CustomPaperContainer>

      <Drawer
        anchor="right"
        open={showModal && typeModal === TYPE_MODAL.Create}
        onClose={onCloseModal}
        disableBackdropClick
        PaperProps={{
          style: {
            width: '50%',
            minWidth: 720,
          },
        }}
      >
        <ModalCreateClinicAppt
          //  loading={loading}
          onSubmit={handleUpdateAppointment}
          selectedItem={selectedItem}
          typeModal={typeModal}
          onClose={() => {
            setShowModal(false);
            fetchData();
          }}
        />
      </Drawer>
      {showModal && typeModal !== TYPE_MODAL.Create && (
        <ModalAddOrEditClinicAppt
          open={showModal}
          onClose={onCloseModal}
          typeModal={typeModal}
          onSubmit={handleUpdateAppointment}
          selectedItem={selectedItem}
        />
      )}

      {showCancelModal && (
        <ModalConfirmation
          open={showCancelModal}
          onClick={onCancelAppointment}
          onClose={() => setShowCancelModal(false)}
          mainContent={'Cancel appointment'}
          subContent={'Are you sure you want to cancel this health screening?'}
          cancelLabel="No, keep it"
          confirmLabel="Confirm"
          confirmColor="red"
        />
      )}
    </>
  );
};

export default ClinicAppointment;
